<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">個人會員</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })">
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })">
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })">
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="exportModal.show">
          <FontAwesome icon="file-export" type="fas" class="w-4 h-4 mr-1" />
          匯出會員資料
        </button>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="importExcel">
          <FontAwesome icon="file-import" type="fas" class="w-4 h-4 mr-1" />
          匯入會員資料
        </button>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="downloadExcel">
          <FontAwesome icon="file-download" type="fas" class="w-4 h-4 mr-1" />
          下載匯入範本
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-select v-model="selectedRights" placeholder="選擇權益" @change="grid.refresh">
              <vxe-option label="全部權益"></vxe-option>
              <vxe-option
                v-for="(item, index) in rights.filter(e => e.value !== 1)"
                :key="'rights-' + index" :label="item.label" :value="item.value" class="py-3"></vxe-option>
            </vxe-select>
          </div> -->
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <div class="mr-1">繳費條件</div>
            <vxe-select v-model="filterRights" placeholder="選擇繳費條件" @change="grid.refresh">
              <vxe-option label="全部"></vxe-option>
              <vxe-option
                v-for="(item, index) in filterRightsList"
                :key="'pay-' + index" :label="item.label" :value="item.value" class="py-3"></vxe-option>
            </vxe-select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <div class="mr-1">郵件條件</div>
            <vxe-select v-model="filterMail" placeholder="選擇郵件條件" @change="grid.refresh">
              <vxe-option label="全部"></vxe-option>
              <vxe-option
                v-for="(item, index) in filterMailList"
                :key="'mail-' + index" :label="item.label" :value="item.value" class="py-3"></vxe-option>
            </vxe-select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @addNewRow="initData" @edit="onGridEdit"
          @remove="remove" @removeSelectedRows="hideBatchDropDown()" @modalResize="onGridModalResize">
          <template #rights="{ row }">
            <vxe-button type="button" content="查看" @click="rightsModal.show(row)"></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="onFormSubmit(row, submit)">
              <template #column-account="{ data }">
                <vxe-input v-model="data.Account" :disabled="data.Id" />
              </template>
              <!-- <template #column-info-channel="{ data }">
                <div class="grid grid-cols-3 gap-4">
                  <div class="col-span-2">
                    <select-box v-bind="infoSelectorOptions" class="custom-vue-selector" v-model="data.InfoChannels" />
                  </div>
                  <div>
                    <vxe-input v-model="data.OtherText" placeholder="其他說明"
                      :disabled="data?.InfoChannels?.indexOf(2048) === -1 || !data?.InfoChannels">
                    </vxe-input>
                  </div>
                </div>
              </template>
              <template #column-industry-category="{ data }">
                <div class="flex" v-if="data.Organization">
                  <SelectBox v-bind="indestryCategorySelectorOptions" v-model="data.Organization.IndustryCategoryId" />
                </div>
              </template>
              <template #column-epaper="{ data }">
                <div class="flex">
                  <select-box class="custom-vue-selector" v-bind="epaperSelectorOptions" v-model="data.Epapers" />
                </div>
              </template>
              <template #column-mii-user-id="{ data }">
                <select-box v-bind="userSelectorOptions" v-model="data.MiiUserId" @change="e => onMiitUserChange(data, e)" />
              </template> -->
              <template #column-city="{ data }">
              <select-box
                v-if="data.Person && data.Person.Address"
                class="!w-full"
                :transfer="true"
                v-bind="citySelectorOptions"
                v-model="data.Person.Address.City"
                @change="changeCity(data, false)"
              />
            </template>
            <template #column-district="{ data }">
              <select-box
                v-if="data.Person && data.Person.Address"
                class="!w-full"
                :transfer="true"
                :focusRefresh="true"
                v-bind="districtSelectorOptions"
                v-model="data.Person.Address.District"
                @change="changeDistrict(data, false)"
              />
            </template>
            <template #column-region="{ data }">
              <select-box
                v-if="data.Person && data.Person.Address"
                class="!w-full"
                :transfer="true"
                :focusRefresh="true"
                v-bind="regionSelectorOptions"
                v-model="data.Person.Address.Region"
              />
            </template>
            <template #column-CertificateIds="{ data }">
              <SelectBox v-model="data.CertificateIds" v-bind="tagSelectorOptions" />
            </template>
            <template #column-IndustryCategoryIds="{ data }">
              <SelectBox v-model="data.IndustryCategoryIds" v-bind="industrySelectorOptions" />
            </template>
            <template #column-TechnicalCategoryIds="{ data }">
              <SelectBox v-model="data.TechnicalCategoryIds" v-bind="technicalSelectorOptions" />
            </template>
            <template #column-Principals="{ data }">
              <PrincipalsTable ref="PrincipalsTable" :aid="data.Id" @refresh="refresh" :data="data.Principals" />
            </template>
            <template #column-Representatives="{ data }">
              <RepresentativesTable ref="RepresentativesTable" :aid="data.Id" @refresh="refresh" :data="data.Representatives" />
            </template>
            <template #shared-title>
              <div class="text-xl border-b-2 border-gray-300">共同欄位</div>
            </template>
            <template #certificate-title>
              <div class="text-xl border-b-2 border-gray-300">專業類別資訊</div>
            </template>
            <template #contact-title>
              <div class="text-xl border-b-2 border-gray-300">聯絡方式</div>
            </template>
            <template #personal-title>
              <div class="text-xl border-b-2 border-gray-300">個人/學生會員資訊</div>
            </template>
            <template #group-title>
              <div class="text-xl border-b-2 border-gray-300">團體會員資訊</div>
            </template>
            <template #column-photo="{ data }">
                <div class="w-full flex justify-center">
                  <file-uploader
                    :ref="el => uploader = el"
                    id="user-photo-uri"
                    style="min-width: 200px; max-width: 200px; min-height: 200px; max-height: 200px;"
                    class="mb-7"
                    mode="image"
                    :headers="{ Authorization: `Basic ${model?.getCertificate(0)}` }"
                    :modelValue="data.Photo?.Uri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="200"
                    :limitedHeight="200"
                    @update:modelValue="(value) => { if (data.Photo) data.Photo.Uri = value; else data.Photo = { Uri: value }; }"
                    @filter="uploaderFilter"
                  />
                </div>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal v-model="rightsModal.visible" width="80%" title="會員權益" height="700" show-zoom="true">
      <RightsTable ref="rightsGrid" v-bind="rightsModal.gridOptions" :sid="rightsModal.selectedRow.Id" />
    </vxe-modal>
    <vxe-modal v-model="exportModal.visible" width="75%" title="匯出會員" height="230" show-zoom="true">
      <ExportTable :visible="exportModal.visible" @save="exportModal.save"/>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
// import { VxeFormProps } from 'vxe-table'
import VXETable, { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString';
import { cityList, districtList, regionList, GetZip } from '@/libs/city';
import RightsTable from "@/components/member/RightsTable.vue";
import ExportTable from "@/components/member/ExportTable.vue";
import PrincipalsTable from "@/components/member/PrincipalsTable.vue";
import RepresentativesTable from "@/components/member/RepresentativesTable.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    RightsTable,
    ExportTable,
    PrincipalsTable,
    RepresentativesTable,
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const users = ref<{ label: string; value: string }[]>([]);
    const districts = ref<{ label: string; value: string }[]>([]);
    const city = ref<{ label: string; value: string }[]>([]);
    const infoChannels = Object.values(model!.enums.WebsiteInfoChannel);
    const epaperTypes = Object.values(model!.enums.EpaperType);
    var setDistrict = (value: any) => { };
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '個人會員',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '會員清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '會員清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      // { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
      // { field: 'Organization.Name', title: '公司名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // {
      //   field: 'Person.Name',
      //   title: '姓名',
      //   showHeaderOverflow: true,
      //   showOverflow: true,
      //   sortable: true,
      //   treeNode: false,
      //   editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
      //   filters: [{ checked: false, label: '包含' }],
      //   slots: { filter: 'filter-name' }
      // },
      // { field: 'Person.Department', title: '部門', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'Person.JobTitle', title: '職稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'Account', title: '帳號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: 'MiiUser.Person.Name', title: '中心人員帳號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: "Person.Name", title: "姓名", showHeaderOverflow: true, showOverflow: true, sortable: true },
      // { field: "Organization.Name", title: "團體名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: "Person.Sex", title: "性別", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.Sex).find(e => e.Value === cellValue)?.Name : undefined },
      { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Status', title: '狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => model ? Object.values(model.enums.MemberStatus).find(e => e.Value === cellValue)?.Name : '' },
      {
        title: '會員權益',
        width: '100',
        slots: { default: "rights" }
      },
      //{ field: "CurrentRights", title: "當前繳費", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          params.condition = new Condition("ApplyType", Operator.NotEqual, 1).and(params.condition!);
          //console.log(selectedRights.value);
          // if(selectedRights.value) {
          //   params.condition = new Condition()
          //      .and("MemberRights", Operator.IsNotNull)
          //      .and("MemberRights.RightsId", Operator.Contains, selectedRights.value.toString())
          //      .and(params.condition!);
          // }
          if(filterRights.value) {
            params.condition = new Condition()
                .and("MemberRights", Operator.IsNotNull)
                .and("MemberRights", Operator.Like, filterRights.value.toString()) //繳費篩選
                .and(params.condition!);
          }

          if(filterMail.value) {
            params.condition = new Condition()
                .and("MemberRights", Operator.IsNotNull)
                .and("MemberRights", Operator.NotLike, filterMail.value.toString()) //mail篩選
                .and(params.condition!);
          }
          return model.dispatch('member/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('member/query') : undefined,
        save: model ? (params) => model.dispatch('member/save', params) : undefined
      },
      modalConfig: { width: "70%", height: 850, showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    // const changeCity = (data: any, property: any) => {
    //   districts.value = districtList(property.value);
    //   if (districts.value) {
    //     setOptions(formOptions.items![19], districts.value);
    //     setDistrict(districts.value[0].value);
    //   }
    // }

    const epaperOption = [{ label: "是", value: true }, { label: "否", value: false }]

    const formOptions  = {
      titleWidth: 110,
      titleAlign: "right",
      customLayout: false,
      items: [
        {
          span: 24,
          children: [
            {
              field: 'SharedTitle',
              span: 24,
              slots: { default: "shared-title" }
            },
            {
              // 入會申請表有這個欄位
              field: 'Number',
              title: '會員證號碼',
              span: 12,
              itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true, }, attrs: { type: 'text' } }
            },
            {
              field: 'ApplyType',
              title: '申請類型',
              span: 12,
              itemRender: { name: "$select", options: model ? Object.values(model.enums.ApplyType).filter(e => e.Value != 1).map(e => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
              field: 'Status',
              title: '會員狀態',
              span: 12,
              itemRender: { name: "$select", options: model ? Object.values(model.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
            }, 
            // {
            //   field: 'Enabled',
            //   title: '是否啟用',
            //   span: 12,
            //   itemRender: { name: '$radio', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            // },
            {
              field: 'MemberType',
              title: '會員類別',
              span: 12,
              itemRender: { name: "$select", options: model ? Object.values(model.enums.MemberType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
              field: 'IsDirector',
              title: '理監事成員',
              span: 12,
              itemRender: { name: '$radio', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            { 
              field: "SecurityCode", 
              title: "會員密碼",
              span: 12,
              itemRender: { name: "$input", props: { type: "password", placeholder: "密碼" } } 
            },
            { field: "Subscribe", title: "訂閱電子報", span: 12, itemRender: { name: "$select", props: { multiple: false }, options: epaperOption } },
            {
              field: 'Remark',
              title: '備註',
              span: 24,
              itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '備註' } }
            },
            {
              field: "Photo.Uri",
              title: "個人大頭貼",
              span: 24, slots: { default: "column-photo" }
            },
            // {
            //   field: 'ValidateCode',
            //   title: '驗證碼',
            //   span: 12,
            //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            // },
            // {
            //   field: 'ValidateTime',
            //   title: '有效期限',
            //   span: 12,
            //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss', placeholder: '請輸入日期', clearable: true } }
            // },
            // {
            //   field: 'AgreedTerms',
            //   title: '同意使用條款',
            //   span: 12,
            //   itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            // },
          ]
        },
        {
          span: 24,
          children: [
            {
              field: 'CertificateTitle',
              span: 24,
              slots: { default: "certificate-title" }
            },
            {
              field: 'CertificateIds',
              title: '證照',
              span: 24,
              slots: { default: "column-CertificateIds" }
            },
            {
              field: 'IndustryCategoryIds',
              title: '產業類別',
              span: 24,
              slots: { default: "column-IndustryCategoryIds" }
            },
            {
              field: 'TechnicalCategoryIds',
              title: '技術類別',
              span: 24,
              slots: { default: "column-TechnicalCategoryIds" }
            },
          ]
        },
        {
          span: 24,
          children: [
            {
              field: 'ContactTitle',
              span: 24,
              slots: { default: "contact-title" }
            },
            {
              field: 'Person.Phone',
              title: '手機/電話',
              span: 16,
              itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'PhonePublished',
              title: '公開電話',
              span: 8,
              itemRender: { name: '$radio', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
              field: 'Email',
              title: '主要信箱',
              span: 16,
              titleWidth: 110,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
            },
            {
              field: 'EmailPublished',
              title: '公開信箱',
              span: 8,
              itemRender: { name: '$radio', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
              field: 'Person.BackupEmail',
              title: '備用信箱',
              span: 16,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
            },
            {
              field: 'BackupEmailPublished',
              title: '公開備用信箱',
              span: 8,
              itemRender: { name: '$radio', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
            },
            {
              field: 'Person.Fax',
              title: '傳真電話',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.HomePhone',
              title: '電話(宅)',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.CompanyPhone',
              title: '電話(公)',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
            },
          ]
        },
        {
          span: 24,
          children: [
            { field: "Person.Address.City", title: "縣市", span: 5, slots: { default: "column-city" } },
            { field: "Person.Address.District", title: "行政區", span: 5, slots: { default: "column-district" } },
            {
              field: 'Person.Address.PostalCode',
              title: '郵遞區號',
              span: 5,
              itemRender: { name: '$input', props: { placeholder: '郵遞區號', clearable: true }, attrs: { type: 'text' } }
            },
            { field: "Person.Address.Region", title: "街路", span: 8, slots: { default: "column-region" } },
            // { field: 'Person.Address.City', title: '縣市', span: 12, itemRender: { name: '$select', options: [], events: { change: changeCity } } },
            // { field: 'Person.Address.District', title: '行政區', span: 12, itemRender: { name: '$select', options: [] } },
            // { field: 'Person.Address.Region', title: '街路', span: 12, itemRender: { name: '$select', options: [] } },
            { field: 'Person.Address.Line', title: '詳細地址', span: 24, itemRender: { name: '$input', props: { placeholder: '詳細地址' } } },
          ]
        },
        {
          span: 24,
          children: [
            {
              field: 'PersonalTitle',
              span: 24,
              slots: { default: "personal-title" }
            },
            {
              field: 'Person.Name',
              title: '姓名',
              span: 12,
              itemRender: { name: '$input', props: { placeholder: '輸入姓名', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.PersonalId',
              title: '身分證字號',
              span: 12,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.BirthDate',
              title: '出生年月日',
              span: 12,
              itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
            },
            {
              field: 'Person.Sex',
              title: '性別',
              span: 12,
              itemRender: { name: "$radio", options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] }
            },
            {
              // 入會申請表有這個欄位
              field: 'Person.Skill',
              title: '個人專長',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
            {
              // 入會申請表有這個欄位
              field: 'Person.Education',
              title: '學歷',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
            {
              // 入會申請表有這個欄位
              field: 'Person.Experience',
              title: '經歷',
              span: 24,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.School',
              title: '學校/科系',
              span: 12,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
            {
              field: 'Person.JobTitle',
              title: '職稱',
              span: 12,
              itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
            },
          ]
        },
        // {
        //   span: 24,
        //   children: [
        //     {
        //       field: 'GroupTitle',
        //       span: 24,
        //       slots: { default: "group-title" }
        //     },            
        //     {
        //       field: 'Organization.Name',
        //       title: '團體名稱',
        //       span: 12,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Organization.EstablishmentDate',
        //       title: '成立日期',
        //       span: 12,
        //       itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        //     },
        //     {
        //       field: 'Organization.TaxId',
        //       title: '統一編號',
        //       span: 12,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Organization.LicenseNumber',
        //       title: '證照字號',
        //       span: 12,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Organization.IssuingAuthority',
        //       title: '發證機關',
        //       span: 12,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Organization.BusinessActivities',
        //       title: '業務項目',
        //       span: 12,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Organization.MembersCount',
        //       title: '會員人數',
        //       span: 12,
        //       itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', clearable: true } }
        //     },
        //     {
        //       field: 'Organization.Link',
        //       title: '官方網址',
        //       span: 24,
        //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        //     },
        //     {
        //       field: 'Principals',
        //       title: '負責人',
        //       span: 24,
        //       slots: { default: "column-Principals" }
        //     },
        //     {
        //       field: 'Representatives',
        //       title: '會員代表',
        //       span: 24,
        //       slots: { default: "column-Representatives" }
        //     },
        //   ]
        // },
        //{ field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        
        // {
        //   field: 'Person.MobilePhone',
        //   title: '手機',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
        // },
      ],
      rules: {
        //'Person.Name': [{ required: true }],
        'Email': [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        'Person.BackupEmail': [{ message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        //MobilePhone: [{ validator: (params) => params.itemValue ? CloudFun.utils.validator.validatePhoneNumber(params.itemValue) : undefined }],
        'Person.HomePhone': [{ validator: (params: any) => params.itemValue ? CloudFun.utils.validator.validatePhoneNumber(params.itemValue) : undefined }],
        'Person.CompanyPhone': [{ validator: (params: any) => params.itemValue ? CloudFun.utils.validator.validatePhoneNumber(params.itemValue) : undefined }],
        'Person.Phone': [{ validator: (params: any) => params.itemValue ? CloudFun.utils.validator.validatePhoneNumber(params.itemValue) : undefined }],
        // 'Person.Fax': [{ validator: (params: any) => params.itemValue ? CloudFun.utils.validator.validatePhoneNumber(params.itemValue) : undefined }],
        'Person.PersonalId': [{
          required: false,
          validator: (params: any) => {
            if (params.itemValue) {
              const regex = new RegExp("[0-9a-zA-z]{8,}");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上僅含英數字");
            }
          }        
        }],
        'SecurityCode': [{
          required: false,
          validator: (params: any) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
              if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
            } else if (!params.data.Id) return new Error("新用戶須設定密碼");
          },
        }],
      }
    };

    // const formOptions: VxeFormProps = {
    //   titleWidth: 140,
    //   titleAlign: 'right',
    //   items: [
    //     {
    //       field: 'Person.Name',
    //       title: '姓名',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     {
    //       field: 'Number',
    //       title: '編號',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
    //     },
    //     { field: "Account", title: "帳號", span: 12, slots: { default: "column-account" } },
    //     // { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號" } } },
    //     { field: "SecurityCode", title: "密碼", span: 12, itemRender: { name: "$input", props: { type: "password", placeholder: "密碼", autocomplete: "new-password" } } },
    //     {
    //       field: 'Person.Sex',
    //       title: '性別',
    //       span: 12,
    //       itemRender: { name: "$select", options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] }
    //     },
    //     { field: "Status", title: "狀態", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
    //     {
    //       field: 'Person.Phone',
    //       title: '聯絡電話',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     {
    //       field: 'Person.MobilePhone',
    //       title: '手機',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入號碼', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     {
    //       field: 'Enabled',
    //       title: '已啟用',
    //       span: 12,
    //       // titleWidth: 100,
    //       itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
    //     },
    //     {
    //       field: 'AgreedTerms',
    //       title: '同意使用條款',
    //       span: 12,
    //       // titleWidth: 120,
    //       itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
    //     },
    //     // {
    //     //   field: 'ValidateCode',
    //     //   title: '驗證碼',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
    //     // },
    //     // {
    //     //   field: 'ValidateTime',
    //     //   title: '有效期限',
    //     //   span: 12,
    //     //   // titleWidth: 100,
    //     //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
    //     // },
    //     { field: "InfoChannels", title: "如何得知", span: 24, slots: { default: "column-info-channel" } },
    //     { field: "Epapers", title: "訂閱電子報", span: 12, slots: { default: "column-epaper" } },
    //     //{ field: "Epapers", title: "訂閱電子報", span: 12, itemRender: { name: "$select", props: { multiple: true, clearable: true }, options: epaperTypes.map(e => ({ label: e.Name, value: e.Value })) } },
    //     {
    //       field: 'MiiUserId',
    //       title: '中心人員帳號',
    //       span: 12,
    //       slots: { default: "column-mii-user-id" }
    //       // titleWidth: 100,
    //       //itemRender: { name: "$select", options: [], props: { placeholder: '請選擇帳號', clearable: true } }
    //     },
    //     // { field: "Email", title: "Email", span: 24, itemRender: { name: "$input", props: { placeholder: "Email" } } },
    //     {
    //       field: 'Organization.IndustryCategoryId',
    //       title: '行業別',
    //       span: 24,
    //       slots: { default: "column-industry-category" }
    //     },
    //     {
    //       field: 'Organization.Name',
    //       title: '公司名稱',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     {
    //       field: 'Organization.IsListed',
    //       title: '是否上市櫃',
    //       span: 12,
    //       // titleWidth: 100,
    //       itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
    //     },
    //     {
    //       field: 'Person.Department',
    //       title: '部門',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     {
    //       field: 'Person.JobTitle',
    //       title: '職稱',
    //       span: 12,
    //       itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
    //     },
    //     { field: 'Person.Address.City', title: '縣市', span: 12, itemRender: { name: '$select', options: [], events: { change: changeCity } } },
    //     { field: 'Person.Address.District', title: '行政區', span: 12, itemRender: { name: '$select', options: [] } },
    //     { field: 'Person.Address.Line', title: '詳細地址', span: 24, itemRender: { name: '$input', props: { placeholder: '詳細地址' } } },
    //     // { field: 'OtherText', title: '其他說明', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
    //     { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
    //   ],
    //   rules: {
    //     'Account': [{ required: true }],
    //     'Organization.Name': [{ required: true }],
    //     'Organization.IndustryCategoryId': [{ required: true }],
    //     'Person.Name': [{ required: true }],
    //     'Person.Department': [{ required: true }],
    //     'Person.JobTitle': [{ required: true }],
    //     // 'Person.Address.City': [{ required: true }],
    //     // 'Person.Address.District': [{ required: true }],
    //     'Person.Address.Line': [{ required: true }],
    //     Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
    //     //Account: [{ required: true }],
    //     SecurityCode: [{
    //       required: false,
    //       validator: (params) => {
    //         if (params.itemValue) {
    //           const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)");
    //           if (!regex.test(params.itemValue)) return new Error("須8碼以上含大小寫英文、數字");
    //         } else if (!params.data.Id) return new Error("新用戶須設定密碼");
    //       },
    //     }],
    //     'Person.Phone': [
    //       {
    //         type: "string",
    //         pattern: new RegExp(
    //           "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
    //         ),
    //       },
    //     ],
    //     'Person.MobilePhone': [
    //       {
    //         type: "string",
    //         required: true,
    //         pattern: new RegExp(
    //           "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
    //         ),
    //       },
    //     ],
    //     //'InfoChannelList': [{ required: true }],
    //     //'EpaperList': [{ required: true }],
    //     InfoChannels: [{ required: true },{
    //         validator({ itemValue }) {
    //           console.log(itemValue == undefined)
    //           if(itemValue == undefined) return new Error("至少選擇一項如何得知");
    //           if (!itemValue || itemValue.length === 0) {
    //             return new Error("至少選擇一項如何得知");
    //           }
    //         }
    //       }],
    //     // Epapers: [{ required: true },{
    //     //     validator({ itemValue }) {
    //     //       console.log(itemValue == undefined)
    //     //       if(itemValue == undefined) return new Error("至少選擇一項訂閱電子報");
    //     //       if (!itemValue || itemValue.length === 0) {
    //     //         return new Error("至少選擇一項訂閱電子報");
    //     //       }
    //     //     }
    //     //   }],
    //   }
    // }

    const init = async () => {
      //const userData = await model?.dispatch("user/query");
      // if (userData) {
      //   users.value = userData.map((e: any) => { return { label: e.Name, value: e.Id } })
      //   setOptions(formOptions.items![12], users.value);
      // }
      // const categoryData = await model?.dispatch("industryCategory/query");
      // if (categoryData) {
      //   setOptions(formOptions.items![13], categoryData.map((e: any) => { return { label: e.Name, value: e.Id } }));
      // }
      city.value = cityList();
      //if (city.value) {
      //  setOptions(formOptions.items![18], city.value);
        //if(city[0]) setOptions(formOptions.items![22], districtList(city[0].value));
      //}
    };

    const setOptions = (item: any, data: any) => {
      if (item.itemRender) {
        var render = item.itemRender;
        render["options"] = data;
      }
    }

    const regions = ref([]);
    const district = ref(null);
    const region = ref(null);

    const initData = (row: any, callback: any) => {
      setDistrict = () => null;
      row.Published = false;
      row.Status = 0;
      row.AgreedTerms = false;
      row.Enabled = true;
      row.InfoChannelList = [];
      row.EpaperList = [];
      row.Organization = {
        IsListed: false,
        MembersCount: 0,
      }

      row.ApplyType = 0;
      row.MemberType = 0;

      district.value = null;
      region.value = null;
      row.PhonePublished = false;
      row.EmailPublished = false;
      row.BackupEmailPublished = false;
      row.AgreedTerms = false;
      row.IsDirector = false;
      row.Subscribe = false;
      //if (city.value)
      //  changeCity(null, city.value[0]);

      row.Person = {
        Sex: 0,
        Address: {
          Region: "",
          City: "", // city.value ? city.value[0].value : '',
          District: "", // districts.value ? districts.value[0].value : '',
        }
      }
      setDistrict = (value: any) => {
        row.Person.Address.District = value;
      }
      callback();
    }

    const selectedRights = ref<any>();
    const rights = ref([]);
    onMounted(async () => {
      try {
        init();
        rights.value = await getRights();
      } catch (error: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error,
        });
      }
    });

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id
        ? await model?.dispatch("member/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
        row.InfoChannelList = infoChannels.filter(e => e.Value === (e.Value & row.InfoChannel)).map(e => e.Value);
        row.EpaperList = epaperTypes.filter(e => e.Value === (e.Value & row.Epaper)).map(e => e.Value);

        row.SecurityCode = undefined;
        district.value = row.Person.Address.District;
        region.value = row.Person.Address.Region;
        
        changeCity(row, true);
        changeDistrict(row, true);

        // setDistrict = (value: any) => {
        //   row.Person.Address.District = value;
        // }
      }
      callback();
    }

    const indestryCategorySelectorOptions = ref<any>({
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      treeConfig: { children: 'Children', accordion: true },
      disallowSelectedRowIds: [],
      columns: [
        {
          field: "Name",
          title: "名稱",
          treeNode: true,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      onSelectionChanged(row: any, callback: Function) {
        if(!row.HasChild) callback();
      },
      promises: {
        find: (value: any) => model!.dispatch("industryCategory/find", value), // eslint-disable-line
        query: (params: any) => {
          if (!params.keyword)
            params.condition!.and('ParentId', Operator.IsNull);
            params.condition!.and('Published', Operator.Equal, true);
          return model!.dispatch('industryCategory/query', params)
        },
      }
    });

    const rightsGrid = ref<any>()
    const rightsModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("MemberId", Operator.Equal, rightsModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("memberRights/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('memberRights/query') : undefined,
          save: model ? (params) => model.dispatch('memberRights/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        rightsModal.visible = true;
        rightsModal.selectedRow = row;
        if (rightsGrid.value) rightsGrid.value.refresh();
      }
    })

    const infoSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WebsiteInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          //else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WebsiteInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const epaperSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.EpaperType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          //else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.EpaperType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    // 只在新增時需要
    const onMiitUserChange = async (row: any, id: number) => {
      if (row.Id) return;
      if (!id) {
        row.MiiUserId = null;
        return;
      }
      const user = await model?.dispatch("user/find", id);
      row.MiiUserId = user?.Status === 10 ? user?.Id : null;
    };

    const getRights = async() => {
      return model?.dispatch('rights/options').then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }


    // const exportExcel = async() => {      
    //   window.open(`${process.env.VUE_APP_BACKEND_URL}/api/Member/ExportExcel`)
    // }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async(ary: []) => {
      try {
        var condition = new Condition();
        if(selectedRights.value) {
          condition = condition.and("MemberRights", Operator.IsNotNull).and("MemberRights.RightsId", Operator.Contains, selectedRights.value.toString())
        }
        var data = await model!.dispatch('member/exportExcel', {keyword: grid.value.keyword, condition: condition, list: ary});
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        const date = formatDate(new Date(), 'yyyyMMdd');
        link.download =`會員資料_${date}.xlsx`;
        link.click();
        //document.body.removeChild(link);
        exportModal.close();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      }
    }

    const importExcel = async () => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        await model?.dispatch('member/importExcel', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入`,
        });
        grid.value.refresh();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      }
    }

    const importExcel2 = async () => {
      try {
        const formData = new FormData();
        await model?.dispatch('member/importExcel', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成新增`,
        });
        grid.value.refresh();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      }
    }

    const downloadExcel = async () => {
      window.open(`${process.env.VUE_APP_BACKEND_URL}/api/Member/DownloadExcel`)
    }

    const exportModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show() {
        exportModal.visible = true;
      },
      close() { exportModal.visible = false; },
      save(ary: any) {
        console.log(ary);
        exportExcel(ary);
      }
    })

    const tagSelectorOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇證照",
      textField: "Title",
      valueField: "Id",
      columns: [{ field: "Title", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value: any) => model?.dispatch("certificate/find", value),
        query: (params: any) => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition);
          params.sortings.push({ column: 'Ordinal', order: 0 });
          return model?.dispatch("certificate/options", params)
        },
      }
    };

    const industrySelectorOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇產業類別",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value: any) => model?.dispatch("estateCategory/find", value),
        query: (params: any) => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition);
          params.sortings.push({ column: 'Ordinal', order: 0 });
          return model?.dispatch("estateCategory/options", params)
        },
      }
    };

    const technicalSelectorOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇技術類別",
      textField: "Name",
      valueField: "Id",
      columns: [{ field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value: any) => model?.dispatch("technicalCategory/find", value),
        query: (params: any) => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition);
          params.sortings.push({ column: 'Ordinal', order: 0 });
          return model?.dispatch("technicalCategory/options", params)
        },
      }
    };

    const citySelectorOptions = {
      rowId: "value",
      transfer: true,
      showSearch: true,
      placeholder: "選擇縣市",
      textField: "label",
      valueField: "value",
      columns: [{ field: "value", title: "縣市", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      showHeader: true,
      promises: {
        find: (value: any) => new Promise((resolve, reject) => resolve(city.value.find(e => e.value == value))), // eslint-disable-line
        query: (params: any) => {
          var result = city.value;
          if (params.keyword) {
            result = city.value.filter(e => e.label.indexOf(params.keyword) !== -1);
          }
          return new Promise((resolve, reject) => resolve({ data: result, totalCount: 1 }));
        },
      }
    };

    const districtSelectorOptions = {
      rowId: "value",
      transfer: true,
      showSearch: true,
      placeholder: "選擇行政區",
      textField: "label",
      valueField: "value",
      columns: [{ field: "value", title: "行政區", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      showHeader: true,
      promises: {
        find: (value: any) => new Promise((resolve, reject) => resolve(districts.value.find(e => e.value == value))), // eslint-disable-line
        query: (params: any) => {
          var result = districts.value;
          if (params.keyword) {
            result = districts.value.filter(e => e.label.indexOf(params.keyword) !== -1);
          }
          return new Promise((resolve, reject) => resolve({ data: result, totalCount: 1 }));
        },
      }
    };

    const regionSelectorOptions = {
      rowId: "value",
      transfer: true,
      showSearch: true,
      placeholder: "選擇街路",
      textField: "label",
      valueField: "value",
      columns: [{ field: "value", title: "街路", showHeaderOverflow: true, showOverflow: true, sortable: true }],
      showHeader: true,
      promises: {
        find: (value: any) => new Promise((resolve, reject) => resolve(regions.value.find((e: any) => e.value == value))), // eslint-disable-line
        query: (params: any) => {
          var result = regions.value;
          if (params.keyword) {
            result = regions.value.filter((e: any) => e.label.indexOf(params.keyword) !== -1);
          }
          return new Promise((resolve, reject) => resolve({ data: result, totalCount: 1 }));
        },
      }
    };

    const changeCity = (data: any, setValue: any) => {
      // console.log(data.Person.Address.City)
      if(data.Person.Address.City) {
        districts.value = districtList(data.Person.Address.City);
        data.Person.Address.District = null;
        if(setValue && district.value)
          data.Person.Address.District = district.value;
        if(!setValue)
          data.Person.Address.PostalCode = null; 
      } else {
        data.Person.Address.City = null;
        data.Person.Address.District = null; 
        data.Person.Address.Region = null;
        data.Person.Address.PostalCode = setValue ? data.Person.Address.PostalCode ?? null : null; 
        districts.value = [];
        regions.value = [];
      }
    }

    const changeDistrict = (data: any, setValue: any) => {
      // console.log(data.Person.Address.District)
      if(data.Person.Address.District && data.Person.Address.City) {
        regions.value = regionList(data.Person.Address.City, data.Person.Address.District);  
        if(!setValue) {
          data.Person.Address.PostalCode = GetZip(data.Person.Address.City, data.Person.Address.District);
        }
        data.Person.Address.Region = null;
        if(setValue && region.value)
          data.Person.Address.Region = region.value;
      } else {
        data.Person.Address.District = null; 
        data.Person.Address.Region = null; 
        data.Person.Address.PostalCode = setValue ? data.Person.Address.PostalCode ?? null : null; 
        regions.value = [];
      }
    }

    const remove = async(row: any, callback: any) => {
      if(row && row.Id) {
        var entity = await model!.dispatch('member/find', row.Id);

        entity.DataMode = 2;

        await model!.dispatch('member/update', entity).then((data) => {
          CloudFun.send("success", {
            subject: "執行成功",
            content: "刪除會員",
          });
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
      //callback();
    }

    const PrincipalsTable = ref<any>(null);
    const RepresentativesTable = ref<any>(null);

    const filterRights = ref<any>();
    const filterRightsList = [
      { label: '當期未繳費', value: 1 },
      { label: '當期已繳費', value: 2 },
      // { label: '未產生繳費', value: 3 },
    ];

    const filterMail = ref<any>();
    const filterMailList = [
      { label: '未寄送通知', value: 1 },
      { label: '已寄送通知', value: 2 },
    ];

    

    return {
      infoChannels,
      epaperTypes,
      grid,
      gridOptions,
      formOptions,
      userSelectorOptions,
      onMiitUserChange,
      initData,
      setDistrict,
      onGridEdit,
      indestryCategorySelectorOptions,
      rightsGrid,
      rightsModal,
      infoSelectorOptions,
      epaperSelectorOptions,
      rights,
      selectedRights,
      exportExcel,
      importExcel,
      downloadExcel,
      exportModal,
      changeDistrict,
      changeCity,
      districtSelectorOptions,
      regionSelectorOptions,
      citySelectorOptions,
      industrySelectorOptions,
      technicalSelectorOptions,
      tagSelectorOptions,
      PrincipalsTable,
      RepresentativesTable,
      remove,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      model,
      filterRights,
      filterRightsList,
      filterMail,
      filterMailList,
      epaperOption,
   }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    hasApproved(status: number) {
      const documentStatus = this.$model.enums.DocumentStatus as any;
      return status === documentStatus.Approved.Value || status === documentStatus.Revoked.Value;
    },
    onFormSubmit(row: any, callback: any) {
      this.epaperTypes.forEach(e => {
        const idx = row.EpaperList.indexOf(e.Value);
        if (idx > -1) row.Epaper |= row.EpaperList[idx];
      })
      this.infoChannels.forEach(e => {
        const idx = row.InfoChannelList.indexOf(e.Value);
        if (idx > -1) row.InfoChannel |= row.InfoChannelList[idx];
      })

      var principals = this.PrincipalsTable?.getData();
      if(principals) {
        if(principals.length > 1) {
          CloudFun.send("error", {
          subject: "執行失敗",
          content: "負責人僅限一筆資料",
        });
          return;
        }
        row.Principals = principals;
      }
      
      var representatives = this.RepresentativesTable?.getData();
      //console.log(principals)
      if(representatives)
        row.Representatives = representatives;
      
      callback();
    }
  }
})
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}

.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}

.customer-required {
  content: "*";
  color: #f56c6c;
  font-family: Verdana, Arial, Tahoma;
  margin-right: 0.2em;
  font-weight: 400;
  vertical-align: middle;
}
</style>